import axios from "axios";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const useApi = () => {
  const fetchAllProjects = async (limit = 10, page = 1, orderBy = "created_on") => {
    try {
      const chain = (localStorage.getItem("selectedChain") || "ethereum")?.toLowerCase();
      const res = await axios.get(
        `${API_ENDPOINT}/api/projects?page=${page}&limit=${limit}&orderBy=${orderBy}&boosted=true&chain=${chain}`,
        {
          headers: {
            chain: chain,
          },
        }
      );
      return res.data;
    } catch (e) {
      console.log("ERROR /projects ", e?.response?.data);
    }
  };
  const fetchAllActivelyTradedProjects = async (page = 1) => {
    try {
      const res = await axios.get(`${API_ENDPOINT}/api/token/active?page=${page}`, {
        headers: {
          chain: (localStorage.getItem("selectedChain") || "ethereum")?.toLowerCase(),
        },
      });
      return res.data;
    } catch (e) {
      //
    }
  };
  const fetchMemeDefiDBProjectByPair = async (poolAddress, chain = "") => {
    try {
      const res = await axios.get(`${API_ENDPOINT}/api/projects/${poolAddress}?type=pool`, {
        headers: {
          chain: chain?.toLowerCase(),
        },
      });
      return res.data;
    } catch (e) {
      console.log(`ERROR /projects/${poolAddress} `, e?.response?.data);
    }
  };
  const fetchMemeDefiDBFavoriteProjectsByPairList = async (poolAddressList = []) => {
    try {
      if (poolAddressList.lengyh === 0) {
        return;
      }
      const res = await axios.post(`${API_ENDPOINT}/api/projects/favorites`, {
        data: poolAddressList,
      });
      return res.data;
    } catch (e) {
      console.log(`ERROR /projects/favorites `, e?.response?.data);
    }
  };
  const fetchMemeDefiTopTrending = async () => {
    try {
      const chain = (localStorage.getItem("selectedChain") || "ethereum")?.toLowerCase();
      const res = await axios.get(`${API_ENDPOINT}/api/projects/trending?chain=${chain}`, {
        headers: {
          chain: chain,
        },
      });
      return res.data?.data ?? {};
    } catch (e) {
      console.log(`ERROR /projects/trending `, e?.response?.data);
    }
  };
  const fetchMemeDefiTopGainers = async () => {
    try {
      const chain = (localStorage.getItem("selectedChain") || "ethereum")?.toLowerCase();
      const res = await axios.get(`${API_ENDPOINT}/api/projects/gainers?chain=${chain}`, {
        headers: {
          chain: chain,
        },
      });
      return res.data?.data ?? {};
    } catch (e) {
      console.log(`ERROR /projects/gainers `, e?.response?.data);
    }
  };
  const fetchMemeDefiTopLosers = async () => {
    try {
      const chain = (localStorage.getItem("selectedChain") || "ethereum")?.toLowerCase();
      const res = await axios.get(`${API_ENDPOINT}/api/projects/losers?chain=${chain}`, {
        headers: {
          chain: chain,
        },
      });
      return res.data?.data ?? {};
    } catch (e) {
      console.log(`ERROR /projects/losers `, e?.response?.data);
    }
  };
  const fetchRecentlyAddedProjects = async (limit = 100, page = 1) => {
    try {
      const chain = (localStorage.getItem("selectedChain") || "ethereum")?.toLowerCase();
      const res = await axios.get(
        `${API_ENDPOINT}/api/projects/recent?limit=${limit}&page=${page}&chain=${chain}`,
        {
          headers: {
            chain: chain,
          },
        }
      );
      return res.data?.data ?? {};
    } catch (e) {
      console.log(`ERROR /projects/recent `, e?.response?.data);
    }
  };
  const fetchProjectTransactionsByContract = async (contractAddress) => {
    try {
      const res = await axios.get(`${API_ENDPOINT}/api/token/${contractAddress}/transactions`, {
        headers: {
          chain: (localStorage.getItem("selectedChain") || "ethereum")?.toLowerCase(),
        },
      });
      return res.data;
    } catch (e) {
      console.log(`ERROR tokens/${contractAddress}/transactions`, e?.response?.data);
    }
  };

  const fetchProjectChartByPair = async (pairAddress, query = "") => {
    try {
      const res = await axios.get(`${API_ENDPOINT}/api/token/${pairAddress}/chart${query}`, {
        headers: {
          chain: (localStorage.getItem("selectedChain") || "ethereum")?.toLowerCase(),
        },
      });
      return res.data;
    } catch (e) {
      console.log(`ERROR tokens/${pairAddress}/chart`, e?.response?.data);
    }
  };

  const fetchProjectByPair = async (pairAddress, referralCode = "", chain = "") => {
    try {
      const res = await axios.get(`${API_ENDPOINT}/api/token/${pairAddress}`, {
        headers: {
          referralcode: referralCode?.toLowerCase(),
          chain: chain?.toLowerCase(),
        },
      });
      return res.data;
    } catch (e) {
      console.log("ERROR /token ", e?.response?.data);
    }
  };

  const searchTokens = async (keyword) => {
    try {
      const res = await axios.post(
        `${API_ENDPOINT}/api/token/search`,
        { keyword },
        {
          headers: {
            chain: (localStorage.getItem("selectedChain") || "ethereum")?.toLowerCase(),
          },
        }
      );
      return res.data;
    } catch (e) {
      console.log("ERROR /token/search ", e?.response?.data);
    }
  };

  const fetchProjectHoldersByContract = async (contractAddress) => {
    try {
      const res = await axios.get(`${API_ENDPOINT}/api/token/${contractAddress}/holders`, {
        headers: {
          chain: (localStorage.getItem("selectedChain") || "ethereum")?.toLowerCase(),
        },
      });
      return res.data;
    } catch (e) {
      console.log(`ERROR  token/${contractAddress}/holders`, e?.response?.data);
    }
  };

  const getPriceOfNativeETHToken = async (chain = "") => {
    try {
      const currentChain = chain || localStorage.getItem("selectedChain");
      if (!currentChain) {
        return;
      }
      const res = await axios.get(`${API_ENDPOINT}/api/token/native/price`, {
        headers: {
          chain: chain?.toLowerCase(),
        },
      });
      return res.data;
    } catch (e) {
      console.log(`ERROR getPriceOfNativeETHToken`, e?.response?.data);
    }
  };

  const getTokenMetadata = async (contractAddress, chain = "", pairAddress) => {
    try {
      const res = await axios.get(
        `${API_ENDPOINT}/api/token/${contractAddress}/metadata?pair=${pairAddress}`,
        {
          headers: {
            chain: chain?.toLowerCase(),
          },
        }
      );
      return res.data;
    } catch (e) {
      console.log(`ERROR getTokenMetadata`, e?.response?.data);
    }
  };

  const getPriceOfTokenByPoolAddress = async (pairAddress) => {
    try {
      const res = await axios.get(`${API_ENDPOINT}/api/token/${pairAddress}/price`, {
        headers: {
          chain: (localStorage.getItem("selectedChain") || "ethereum")?.toLowerCase(),
        },
      });
      return res.data;
    } catch (e) {
      console.log(`ERROR getPriceOfTokenByPoolAddress`, e?.response?.data);
    }
  };

  const getTokenAuditDataByContract = async (contractAddress, chain = "") => {
    try {
      const res = await axios.get(`${API_ENDPOINT}/api/token/${contractAddress}/audit`, {
        headers: {
          chain: chain?.toLowerCase(),
        },
      });
      return res.data;
    } catch (e) {
      console.log(`ERROR getTokenAuditDataByContract`, e?.stack);
    }
  };

  const getAllSocialsForProject = async (projectId) => {
    try {
      const res = await axios.get(`${API_ENDPOINT}/api/socials/project/${projectId}`);
      return res.data;
    } catch (e) {
      console.log(`ERROR getAllSocialsForProject`, e?.response?.data);
    }
  };

  const createListingRequest = async (payload) => {
    try {
      const res = await axios.post(`${API_ENDPOINT}/api/socials/listing`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (e) {
      console.log(`ERROR createListingRequest`, e?.response?.data);
      throw new Error(e?.response?.data?.message ?? "Something went wrong");
    }
  };

  const resetCache = async (key = "") => {
    try {
      const res = await axios.get(`${API_ENDPOINT}/api/projects/clear-cache`);
      return res.data;
    } catch (e) {
      console.log("ERROR /projects/clear-cache ", e?.response?.data);
    }
  };

  const resetTokenCache = async () => {
    try {
      const res = await axios.get(`${API_ENDPOINT}/api/token/clear-cache`);
      return res.data;
    } catch (e) {
      //
    }
  };

  const createEventForProject = async (projectId, reqPayload = {}) => {
    if (!projectId) {
      return;
    }
    try {
      const res = await axios.post(`${API_ENDPOINT}/api/events/`, reqPayload, {
        headers: {
          token: localStorage.getItem("0xhub-token"),
        },
      });
      return res.data;
    } catch (e) {
      console.log("ERROR /events ", e?.response?.data);
    }
  };
  const getEventsForProject = async (projectId) => {
    if (!projectId) {
      return;
    }
    try {
      const res = await axios.get(`${API_ENDPOINT}/api/events/project/${projectId}`);
      return res.data;
    } catch (e) {
      console.log(`ERROR /events/project/${projectId}`, e?.response?.data);
    }
  };
  const getTodayEventVoteForProjectByUser = async (projectId) => {
    if (!projectId) {
      return;
    }
    try {
      const res = await axios.get(`${API_ENDPOINT}/api/events/project/${projectId}/vote-status`, {
        headers: {
          token: localStorage.getItem("0xhub-token"),
        },
      });
      return res.data;
    } catch (e) {
      console.log(`ERROR /events/project/${projectId}/vote-status`, e?.response?.data);
    }
  };

  const authenticateUserByGoogle = async (code = "") => {
    if (!code) {
      return;
    }
    try {
      const res = await axios.post(`${API_ENDPOINT}/api/auth/google`, { code });
      return res.data;
    } catch (e) {
      // console.log("ERROR /auth/google ", e?.response?.data);
    }
  };

  const loginUserByUsernamePassword = async (payload = {}) => {
    if (!payload.email || !payload.password) {
      return;
    }
    try {
      const res = await axios.post(`${API_ENDPOINT}/api/auth/login`, payload);
      return res.data;
    } catch (e) {
      throw new Error(e?.response?.data?.message ?? "Authentication failed");
    }
  };

  const signupUserByUsernamePassword = async (payload = {}) => {
    if (!payload.email || !payload.password || !payload.name) {
      return;
    }
    try {
      const res = await axios.post(`${API_ENDPOINT}/api/auth/signup`, payload);
      return res.data;
    } catch (e) {
      throw new Error(e?.response?.data?.message ?? "Signup failed");
    }
  };

  const verifyEmailAccount = async (payload = {}) => {
    if (!payload.code) {
      return;
    }
    try {
      const res = await axios.post(`${API_ENDPOINT}/api/auth/verify`, payload, {
        headers: {
          token: localStorage.getItem("0xhub-token"),
        },
      });
      return res.data;
    } catch (e) {
      throw new Error(e?.response?.data?.message ?? "Email verification failed");
    }
  };

  const initiateResetPassword = async (email) => {
    if (!email) {
      return;
    }
    try {
      const res = await axios.post(`${API_ENDPOINT}/api/auth/init-reset-password`, { email });
      return res.data;
    } catch (e) {
      throw new Error(e?.response?.data?.message ?? "Sending email to reset password failed");
    }
  };

  const resetPassword = async (payload) => {
    if (!(payload.passwordOne && payload.passwordTwo)) {
      return;
    }
    try {
      const res = await axios.post(`${API_ENDPOINT}/api/auth/reset-password`, payload);
      return res.data;
    } catch (e) {
      throw new Error(e?.response?.data?.message ?? "Password reset failed");
    }
  };

  const resendVerificationCode = async (payload = {}) => {
    try {
      const res = await axios.get(`${API_ENDPOINT}/api/auth/resend-code`, {
        headers: {
          token: localStorage.getItem("0xhub-token"),
        },
      });
      return res.data;
    } catch (e) {
      throw new Error(e?.response?.data?.message ?? "Resend verification code failed");
    }
  };

  const getProfileInfo = async (userId) => {
    if (!userId) {
      return;
    }
    try {
      const res = await axios.get(`${API_ENDPOINT}/api/users/${userId}`, {
        headers: {
          token: localStorage.getItem("0xhub-token"),
        },
      });
      return res.data;
    } catch (e) {
      throw new Error(e?.response?.data?.message ?? "Failed to fetch user profile");
    }
  };

  const updateUserInfo = async (reqPayload = {}) => {
    if (!walletAddress) {
      return;
    }
    try {
      const res = await axios.patch(`${API_ENDPOINT}/api/users/`, reqPayload, {
        headers: {
          token: localStorage.getItem("0xhub-token"),
        },
      });
      return res.data;
    } catch (e) {
      throw new Error(e?.response?.data?.message ?? "Failed to save user info");
    }
  };

  const createOrUpdateReferral = async (code) => {
    if (!code) {
      return;
    }
    try {
      const res = await axios.post(
        `${API_ENDPOINT}/api/users/referral`,
        { code },
        {
          headers: {
            token: localStorage.getItem("0xhub-token"),
          },
        }
      );
      return res.data;
    } catch (e) {
      throw new Error(e?.response?.data?.message ?? "Failed to fetch user profile");
    }
  };

  const createOrUpdateRewardWallet = async (walletAddress) => {
    if (!walletAddress) {
      return;
    }
    try {
      const res = await axios.patch(
        `${API_ENDPOINT}/api/users/`,
        { reward_wallet: walletAddress },
        {
          headers: {
            token: localStorage.getItem("0xhub-token"),
          },
        }
      );
      return res.data;
    } catch (e) {
      throw new Error(e?.response?.data?.message ?? "Failed to save user wallet address");
    }
  };

  const getReferralCodeData = async () => {
    try {
      const res = await axios.get(`${API_ENDPOINT}/api/users/referral`, {
        headers: {
          token: localStorage.getItem("0xhub-token"),
        },
      });
      await resetUsersCache();
      return res.data;
    } catch (e) {
      throw new Error(e?.response?.data?.message ?? "Failed to fetch user referral code");
    }
  };

  const getReferralStatsDataForUser = async () => {
    try {
      const res = await axios.get(`${API_ENDPOINT}/api/users/referral-stats`, {
        headers: {
          token: localStorage.getItem("0xhub-token"),
        },
      });
      return res.data;
    } catch (e) {
      throw new Error(e?.response?.data?.message ?? "Failed to fetch user referral code");
    }
  };

  const resetUsersCache = async () => {
    try {
      const res = await axios.get(`${API_ENDPOINT}/api/users/clear-cache`);
      return res.data;
    } catch (e) {
      //
    }
  };

  const getAdvertisements = async () => {
    try {
      const res = await axios.get(`${API_ENDPOINT}/api/advertisements`);
      return res.data;
    } catch (e) {
      throw new Error(e?.response?.data?.message ?? "Failed to fetch ads");
    }
  };

  const createAdvertisement = async (payload) => {
    try {
      const res = await axios.post(`${API_ENDPOINT}/api/advertisements`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (e) {
      throw new Error(e?.response?.data?.message ?? "Failed to create ads");
    }
  };

  const createAdImpression = async (payload) => {
    try {
      const res = await axios.post(`${API_ENDPOINT}/api/advertisements/impression`, payload);
      return res.data;
    } catch (e) {
      throw new Error(e?.response?.data?.message ?? "Failed to create ad impression");
    }
  };

  return {
    fetchAllProjects,
    fetchProjectByPair,
    searchTokens,
    fetchProjectHoldersByContract,
    fetchProjectTransactionsByContract,
    fetchProjectChartByPair,
    getPriceOfNativeETHToken,
    getPriceOfTokenByPoolAddress,
    resetCache,
    resetTokenCache,
    fetchMemeDefiDBProjectByPair,
    fetchMemeDefiTopTrending,
    fetchMemeDefiTopGainers,
    fetchMemeDefiTopLosers,
    fetchRecentlyAddedProjects,
    getAllSocialsForProject,
    createListingRequest,

    createEventForProject,
    getEventsForProject,
    getTodayEventVoteForProjectByUser,
    fetchMemeDefiDBFavoriteProjectsByPairList,
    getTokenAuditDataByContract,
    fetchAllActivelyTradedProjects,

    authenticateUserByGoogle,
    signupUserByUsernamePassword,
    verifyEmailAccount,
    resendVerificationCode,
    initiateResetPassword,
    resetPassword,
    loginUserByUsernamePassword,
    getProfileInfo,
    createOrUpdateReferral,
    getReferralCodeData,
    getReferralStatsDataForUser,
    createOrUpdateRewardWallet,
    resetUsersCache,
    updateUserInfo,

    getAdvertisements,
    createAdvertisement,
    createAdImpression,
    getTokenMetadata,
  };
};

export default useApi;
