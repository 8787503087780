/* eslint-disable react/prop-types */

import { useState, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { toLower, truncate } from "lodash";

import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

import { useMaterialUIController } from "context";
import useApi from "utils/useApi";

const TextAd = ({ contractAddress }) => {
  const [controller] = useMaterialUIController();
  const location = useLocation();
  const [showAd, setShowAd] = useState(true);

  // const { getAdvertisements } = useApi();
  const { darkMode, transparentNavbar } = controller;

  // const { data: advertisementDataRes = {} } = useQuery(
  //   `getProjectNotifications`,
  //   () => getProjectNotifications(),
  //   {
  //     retry: false,
  //     refetchOnWindowFocus: false,
  //     enabled: false,
  //   }
  // );

  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = darkMode ? white.main : dark.main;

      if (transparentNavbar) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const advertisementData = [];
  const textAdData = {
    contract: "0x876a87e17d76e1487186e00792985918abadca6b",
    header: "MemeDefi is 0xHub🚀",
    link_to: "",
    subheader: "0xHub is gearing up 🚀. Stay tuned!",
  };

  const { header, media: logo, link_to: link, subheader: content } = textAdData || {};

  if (toLower(contractAddress) !== toLower(textAdData?.contract)) {
    return;
  }

  if (!showAd) {
    return;
  }

  return (
    <Card
      sx={{
        "&.MuiPaper-root": {
          padding: "4px",
          marginBottom: "4px",
          background: {
            xs: "#1A1F2B",
            sm: "#FFFFFF1A",
          },
        },
      }}
    >
      <CardHeader
        title={
          <MDTypography
            variant="button"
            fontWeight="medium"
            fontSize="14px"
            sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}
          >
            <MDAvatar
              size="xs"
              sx={({ borders: { borderWidth }, palette: { dark, white, text } }) => ({
                border: `${borderWidth[1]} solid ${darkMode ? white.main : dark.main}`,
                cursor: "pointer",
                position: "relative",
                mr: "8px",

                "&:hover, &:focus": {
                  zIndex: "10",
                },
              })}
            >
              <AnnouncementIcon color="info" />
            </MDAvatar>
            Recent News:&nbsp;
            {/* Movile view */}
            <MDTypography
              variant="p"
              color="text"
              fontWeight="regular"
              fontSize="13px"
              sx={{
                display: {
                  xs: "block",
                  sm: "none",
                },
              }}
            >
              <i style={{ fontWeight: "normal" }}>
                {truncate(header, {
                  length: 28,
                })}
              </i>
            </MDTypography>
            {/* Big screen */}
            <MDTypography
              variant="p"
              color="text"
              fontWeight="regular"
              fontSize="13px"
              sx={{
                display: {
                  xs: "none",
                  sm: "block",
                },
              }}
            >
              <i style={{ fontWeight: "normal" }}>
                {truncate(header, {
                  length: 40,
                })}
              </i>
            </MDTypography>
          </MDTypography>
        }
        action={
          <Icon
            fontSize="small"
            sx={iconsStyle}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setShowAd(false);
            }}
            style={{ cursor: "pointer", paddingTop: "2px" }}
          >
            cancel
          </Icon>
        }
        sx={{
          padding: "8px 12px 8px 4px",
          paddingBottom: "2px",
          marginBottom: "6px",
        }}
        component={link ? Link : null}
        to={link}
        target="_blank"
      />
      <Divider
        sx={{
          margin: 0,
          marginBottom: "8px",
          display: {
            xs: "block",
            sm: "none",
          },
        }}
      />
      <MDBox
        sx={{
          textAlign: "center",
          lineHeight: "1",
          display: {
            xs: "none",
            sm: "flex",
          },
        }}
        borderRadius="sm"
        p="4px"
        pb={"8px"}
        pt={"0px"}
        display="flex"
        justifyContent="flex-start"
      >
        <MDTypography
          variant="p"
          color="text"
          fontWeight="regular"
          fontSize="13px"
          style={{ textAlign: "left" }}
        >
          {truncate(content, {
            length: 100,
          })}
        </MDTypography>
      </MDBox>
    </Card>
  );
};

TextAd.defaultProps = {
  // contractAddress: false,
};

// Typechecking props for the TextAd
TextAd.propTypes = {
  // contractAddress: PropTypes.bool,
};
export default TextAd;
